<template>
  <div
    class="microcart bg-cl-main-smoke mw-100 fixed"
    data-testid="microcart"
  >
    <div class="row m0 microcart-header middle-xs between-xs bg-cl-white">
      <div class="pointer col-xs-2" v-if="productsInCart.length" @click="clearCart">
        <p class="clear-cart weight-600 h6 m0">
          {{ $t('Clear cart') }}
        </p>
      </div>
      <div v-if="!productsInCart.length" class="visible-xs pointer">
        <img class="flex" alt="Kultowy.pl Logo" src="/assets/kultowy-logo.svg" height="40" width="65">
      </div>
      <div>
        <h2
          v-if="productsInCart.length"
          class="microcart-title py15 m0 cl-black"
        >
          {{ $t('Shopping cart') }}
        </h2>
        <h2 v-if="!productsInCart.length" class="h3 empty-microcart m0 cl-black">
          {{ $t('Your shopping cart is empty.') }}
        </h2>
      </div>
      <button
        type="button"
        class="p0 brdr-none col-xs-2 align-right bg-cl-transparent"
        @click="closeMicrocartExtend"
        data-testid="closeMicrocart"
      >
        <i class="icon-zamknij cl-main weight-900 h3" />
      </button>
    </div>
    <div v-if="!productsInCart.length" class="ml30 mt50 cl-black" @click="closeMicrocartExtend">
      {{ $t("Don't hesitate and") }}
      <router-link class="cl-main browse-link" :to="localizedRoute('/')">
        {{ $t('browse our catalog') }}
      </router-link>
      {{ $t('to find something beautiful for You!') }}
    </div>
    <div
      v-if="productsInCart.length && !isEdge"
      class="products visible-xs withoutInstant mt5"
    >
      <guide-notification class="visible-xs" />
      <component :is="SwipeActions" :products="productsInCart" />
    </div>
    <ul v-if="productsInCart.length" class="bg-cl-main-smoke m0 px40 pb40 products" :class="{ 'hidden-xs' : !isEdge }">
      <product v-for="product in productsInCart" :key="product.server_item_id || product.id" :product="product" />
    </ul>
    <div class="footer-bottom env-padding-bottom">
      <div v-if="productsInCart.length" class="summary px40 cl-accent serif bg-cl-white">
        <div v-show="$device.isMobile" class="row inner-container m0 end-xs">
          <div class="col-xs-12 col-md-12 pl0">
            <p class="p ff-semi mobile-info cl-black align-left">
              {{ $t('Select the shipping and delivery method in the next step') }}
            </p>
          </div>
          <!-- <div v-show="$device.isMobile" class="flex middle-xs center-xs col-xs-1">
            <div @click="showMicrocartMobile = !showMicrocartMobile" :class="showMicrocartMobile ? 'custom-icon-v2' : 'custom-icon'" class="flex center-xs middle-xs ">
              <i :class="showMicrocartMobile ? 'down' : 'top'" class="arrow" />
            </div>
          </div> -->
        </div>
        <!-- <div v-show="showMicrocartMobile || !$device.isMobile">
          <div v-for="(segment, index) in totals" :key="index" class="row price-section cl-black" v-if="segment.code !== 'grand_total' && segment.code !== 'tax' && segment.code !== 'shipping'">
            <div class="col-xs" :class="{'weight-900': $device.isMobile}">
              {{ segment.title }}
              <button v-if="appliedCoupon && segment.code === 'discount'" type="button" class="p0 brdr-none bg-cl-transparent close delete-button ml10" @click="clearCoupon">
                <i class="icon-X_black cl-accent" />
              </button>
            </div>
            <div v-if="segment.value != null" class="col-xs align-right" :class="{'weight-900': $device.isMobile}">
              <template v-if="segment.code === 'shipping'">
                {{ Math.abs(segment.value * 1.23) | price(storeView) }}
              </template>
              <template v-else>
                {{ segment.value | price(storeView) }}
              </template>
            </div>
          </div>
        </div> -->
        <div class="row inner-container m0">
          <div v-if="OnlineOnly && !addCouponPressed" class="col-xs-12 p0 hidden-xs">
            <button
              class="p0 flex bottom-xs brdr-none serif fs-medium-small cl-black bg-cl-transparent"
              type="button"
              @click="addDiscountCoupon"
            >
              {{ $t('Add a discount code') }} <i class="icon-question h4 cl-main pl10" />
            </button>
          </div>
          <div v-if="OnlineOnly && !addCouponPressed && showMicrocartMobile" class="col-xs-12 p0 mb10 visible-xs">
            <button
              class="flex add-coupon bottom-xs brdr-none serif fs-medium-small cl-black bg-cl-transparent"
              type="button"
              @click="addDiscountCoupon"
            >
              {{ $t('Add a discount code') }}
            </button>
          </div>
          <div v-if="OnlineOnly && addCouponPressed" class="col-xs-12 pt15 p0 coupon-wrapper middle-xs">
            <div class="coupon-input">
              <base-input class="" type="text" id="couponinput" :autofocus="true" v-model.trim="couponCode" @keyup.enter="setCoupon" />
            </div>
            <button-outline color="dark" class="discount-btn" :disabled="!couponCode" @click.native="setCoupon">
              {{ $t('Add discount code') }}
            </button-outline>
          </div>
        </div>
        <div class="col-xs-12 col-md-12 pl0 hidden-xs">
          <p class="p ff-semi cl-black my10 fs-medium-small">
            {{ $t('Select the shipping and delivery method in the next step') }}
          </p>
        </div>
        <div class="row py10 weight-700 middle-xs m0 price-wrapper" :class="{'brdr-top-1 brdr-cl-matterhorn': showMicrocartMobile}" v-for="(segment, index) in totals" :key="index" v-if="segment.code === 'grand_total'">
          <div class="col-xs h4 total-price-label cl-black p0">
            {{ segment.title }}:
          </div>
          <div class="col-xs align-right h3 total-price-value cl-black">
            {{ totalWithoutShipping | price(storeView) }}
          </div>
        </div>
      </div>
      <div
        class="m0 row py20 px40 middle-xs actions bg-cl-white between-xs"
        v-if="productsInCart.length && !isCheckoutMode"
      >
        <div class="col-xs-12 col-md-6 pl0 hidden-xs">
          <router-link :to="localizedRoute('/')" class="row col-xs-12 center-xs no-underline cl-main link btn-shopping m0">
            <span @click="closeMicrocartExtend">
              {{ $t('Return to shopping') }}
            </span>
          </router-link>
        </div>
        <div class="col-xs-12 col-md-6 pr0">
          <button-full
            :link="{ name: 'checkout' }"
            class="btn-checkout"
            @click="closeMicrocartExtend"
          >
            {{ $t('Go to checkout') }}
          </button-full>
          <!-- <instant-checkout v-if="isInstantCheckoutRegistered" class="no-outline button-full block brdr-none w-100 px10 py20 bg-cl-mine-shaft :bg-cl-th-secondary ripple weight-400 h4 cl-white sans-serif fs-medium mt20" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import i18n from '@vue-storefront/i18n'
import { isModuleRegistered } from '@vue-storefront/core/lib/modules'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import VueOfflineMixin from 'vue-offline/mixin'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import InstantCheckout from 'src/modules/instant-checkout/components/InstantCheckout.vue'
import { registerModule } from '@vue-storefront/core/lib/modules'
import GuideNotification from 'theme/components/core/blocks/Microcart/GuideNotification'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import Product from 'theme/components/core/blocks/Microcart/Product'
import EditMode from './EditMode'
import { InstantCheckoutModule } from 'src/modules/instant-checkout'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'

const SwipeActions = () => import(/* webpackPreload: true */ /* webpackChunkName: "SwipeActions" */ 'theme/components/core/blocks/Microcart/SwipeActions.vue')

export default {
  components: {
    Product,
    ButtonFull,
    ButtonOutline,
    BaseInput,
    InstantCheckout,
    GuideNotification
  },
  mixins: [
    VueOfflineMixin,
    EditMode,
    onEscapePress,
    BrowserDetected
  ],
  data () {
    return {
      showMicrocartMobile: true,
      SwipeActions,
      addCouponPressed: true,
      couponCode: '',
      componentLoaded: false,
      isInstantCheckoutRegistered: isModuleRegistered('InstantCheckoutModule')
    }
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  beforeCreate () {
    registerModule(InstantCheckoutModule)
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
      if (this.isMicrocartOpen) {
        let body = document.getElementsByTagName('body')[0]
        body.classList.add('block-scroll')
      }
    })
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('block-scroll')
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      appliedCoupon: 'cart/getCoupon',
      totals: 'cart/getTotals',
      isOpen: 'cart/getIsMicroCartOpen'
    }),
    storeView () {
      return currentStoreView()
    },
    totalWithoutShipping () {
      let grand = this.totals.find(x => x.code === 'grand_total')
      let shipping = this.totals.find(x => x.code === 'shipping')
      if ((grand && grand.code === 'grand_total') && (shipping && shipping.code === 'shipping')) {
        let priceFilter = function (price) { return (Math.ceil(price * 100) / 100).toFixed(2) }
        return priceFilter(grand.value - Math.abs(shipping.value * 1.23))
      } else if ((grand && grand.code === 'grand_total') && !shipping) {
        return grand.value
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    addDiscountCoupon () {
      this.addCouponPressed = true
    },
    clearCoupon () {
      this.$store.dispatch('cart/removeCoupon')
      this.addCouponPressed = false
    },
    toggleMicrocart () {
      this.$store.dispatch('ui/toggleMicrocart')
    },
    async setCoupon () {
      const couponApplied = await this.applyCoupon(this.couponCode)
      this.addCouponPressed = false
      this.couponCode = ''
      if (!couponApplied) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t("You've entered an incorrect coupon code. Please try again."),
          action1: { label: i18n.t('OK') }
        })
      }
    },
    closeMicrocartExtend () {
      this.toggleMicrocart()
      this.$store.commit('ui/setSidebar', false)
      this.addCouponPressed = false
    },
    onEscapePress () {
      this.$store.dispatch('ui/closeMicrocart')
    },
    clearCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('Are you sure you would like to remove all the items from the shopping cart?'),
        action1: { label: i18n.t('Cancel'), action: 'close' },
        action2: { label: i18n.t('OK'),
          action: async () => {
            // We just need to clear cart on frontend and backend.
            // but cart token can be reused
            await this.$store.dispatch('cart/clear', { disconnect: false })
          }
        },
        hasNoTimeout: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/animations/transitions";
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-black: color(black);
  $color-main: color(main);

  .microcart {
    top: 0;
    right: 0;
    z-index: 3;
    height: 100%;
    width: 800px;
    min-width: 320px;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    @media (max-width: 767px) {
      overflow: hidden;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 100%;
    }
  }
  .price-wrapper {
    @media (max-width: 767px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .mobile-info {
    @media (max-width: 767px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .microcart-title {
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .clear-cart {
    color: $color-black;
  }
  .microcart-header {
    padding: 0 40px;
    @media (max-width: 767px) {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      box-shadow: 0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
      -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
      padding: 0 8px;
    }
  }
  .empty-microcart {
    padding: 15px 0;
    @media (max-width: 767px) {
      font-size: 16px;
      padding: 15px 0;
    }
  }
  .microcart>.products {
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    // height: calc(100vh - 325px);
    max-height: calc(100vh - 300px);
    @media (max-width: 768px) {
      max-height: calc(100vh - 220px);
    }
  }
  .footer-bottom {
    @media (min-width: 768px) {
      width: 800px;
    }
    position: fixed;
    bottom: 0;
    @media (max-width: 767px) {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      left: 0;
      right: 0;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 100%;
    }
  }
  .inner-container {
    padding-bottom: 10px;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
  .custom-icon {
    border: 1px solid $color-main;
    padding: 11px 10px 6px 10px;
    border-radius: 50%;
  }
  .custom-icon-v2 {
    border: 1px solid $color-main;
    padding: 7px 10px 10px 10px;
    border-radius: 50%;
  }
  .arrow {
    border: solid $color-main;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
  }
  .top {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .add-coupon {
    border: 1px solid;
    border-radius: 16px;
    padding: 5px 12px;
  }
  .discount-btn {
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    @media (max-width: 767px) {
      padding: 9px 0px;
      font-weight: 300;
      margin-left: 8px;
      font-size: 13px;
      border: 2px solid $color-main;
    }
  }
  .btn-checkout {
    border-radius: 26px;
    @media (max-width: 767px) {
      padding: 10px 30px;
      font-size: 16px !important;
    }
  }
  .btn-shopping {
    border: 3px solid #1396EC;
    color: #1396EC;
    padding: 13px 30px;
    border-radius: 26px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  @media (hover: hover) {
    .browse-link {
      &:hover:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 97%;
        height: 1px;
        background-color: #1396EC;
      }
    }
  }
  .mt0 {
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  .clearcart {
    &-col {
      display: flex;
      align-self: center;
    }
  }

  .products {
    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }

  .actions {
    @media (max-width: 767px) {
      padding: 15px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    .link {
      @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 20px 70px;
        &.checkout {
          margin-top: 55px;
          padding: 0;
        }
      }
    }
  }
  .price-section {
    padding: 10px 0;
    font-weight: 600;
    font-size: 14px;
    @media (max-width: 767px) {
      padding: 5px 0;
    }
  }
  .summary {
    @media (max-width: 767px) {
      padding:  0 10px;
      font-size: 12px;
    }
  }

  .summary-heading {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-label {
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .total-price-value {
    @media (max-width: 767px) {
      padding: 0;
      font-size: 24px;
    }
  }

  .delete-button {
    vertical-align: middle;
  }

  .coupon-wrapper {
    display: flex;
    align-items: flex-end;
    .button-outline {
      text-transform: inherit;
      width: 50%;
    }
    .coupon-input {
      margin-right: 20px;
      width: 100%;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: row;
      -ms-flex-direction: row;
      padding-top: 0;
      padding-bottom: 11px;
      .coupon-input {
        margin-right: 0;
        width: 100%;
      }
      .coupon-input label {
        margin-bottom: 5px;
      }
      .button-outline {
        width: 100%;
      }
    }
  }

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    height: 100%;
    background:rgba(0, 0, 0, 0.4);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
