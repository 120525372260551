<template>
  <li class="row p10 border-li m0" :class="{ 'relative': editMode }">
    <div class="mx10 w-100 py10 edit-mode flex between-xs middle-xscl-main" v-if="editMode">
      <button class="brdr-none bg-cl-transparent" @click="closeEditMode">
        <span class="cl-accent">
          <span class="cl-main">{{ $t("Finish editing") }}</span>
        </span>
      </button>
    </div>
    <div class="blend">
      <div class="bg-cl-white">
        <product-image class="pt-100" :image="image" />
      </div>
    </div>
    <div class="col-xs pt15 flex pl30 pr0 flex-wrap">
      <div class="flex flex-nowrap details">
        <div class="flex col-xs-9 flex-wrap between-xs p0">
          <div class="pt10 w-100">
            <router-link
              class="serif fs-medium weight-600 name cl-black"
              :to="productLink"
              data-testid="productLink"
              @click.native="$store.commit('ui/setMicrocart', false)"
            >
              {{ product.name | htmlDecode }}
            </router-link>
            <div class="h6 cl-black pt5 sku" data-testid="productSku">
              {{ product.sku }}
            </div>
            <div class="h6 cl-black pt5 options" v-if="isTotalsActive">
              <div v-for="(opt, index) in product.totals.options" :key="opt.label + opt.value + index">
                <span class="opn">{{ $t(opt.label) }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <div class="h6 cl-black pt5 options" v-else-if="!editMode && product.options">
              <div v-for="(opt, index) in product.options" :key="opt.label + opt.value + index">
                <span class="opn">{{ $t(opt.label) }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <span v-show="omnibusPriceMap[product.sku] && isSaleDifference" class="h6 cl-black">
              {{ $t(`Najniższa cena w ostatnich 30 dniach: ${omnibusPriceMap[product.sku]} zł`) }}
            </span>
            <!-- <div class="h6 pt5 cl-error" v-if="hasProductErrors">
              {{ product.errors | formatProductMessages }}
            </div>
            <div class="h6 pt5 cl-success" v-if="hasProductInfo && !hasProductErrors">
              {{ product.info | formatProductMessages }}
            </div> -->
          </div>
          <product-quantity
            class="h5 cl-accent lh25 qty"
            :class="{'pt20': editMode}"
            :value="productQty"
            :max-quantity="maxQuantity"
            :loading="isStockInfoLoading"
            :is-simple-or-configurable="isSimpleOrConfigurable"
            @input="updateProductQty"
            @click="updateProductQty"
            @error="handleQuantityError"
          />
        </div>
        <div class="row col-xs-3 align-right start-xs between-sm prices p0">
          <div class="flex flex-col bottom-xs col-xs-12 p0 between-xs" v-if="!editMode">
            <div class="links flex flex-col bottom-xs">
              <remove-button class="" @click="removeItem" />
              <span class="h6 cl-main mt15 weight-600 pointer" @click="openEditMode" v-if="productsAreReconfigurable && !editMode">
                {{ $t('Edit') }}
              </span>
            </div>
            <div class="prices" v-if="!displayItemDiscounts || !isOnline">
              <span class="h4 serif cl-error price-special" v-if="product.special_price">
                {{ product.price_incl_tax * product.qty | price(storeView) }}
              </span>
              <span class="h6 serif price-original" v-if="product.special_price">
                {{ product.original_price_incl_tax * product.qty | price(storeView) }}
              </span>
              <span class="h4 serif price-regular cl-black" v-else data-testid="productPrice">
                {{ (product.original_price_incl_tax ? product.original_price_incl_tax : product.price_incl_tax) * product.qty | price(storeView) }}
              </span>
            </div>
            <div class="prices" v-else-if="isOnline && product.totals">
              <span
                v-if="product.totals.discount_amount || bundleMinPrice || isSpecialPrice"
                class="h4 serif price-special"
                :class="[ omnibusPriceMap[product.sku] && isSaleDifference ? 'cl-error' : 'cl-black' ]"
              >
                <span v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax - product.totals.discount_amount | price(storeView) }}</span>
                <span v-else>{{ product.totals.row_total_incl_tax | price(storeView) }}</span>
              </span>
              <span class="h6 serif price-original" v-if="
                product.totals.discount_amount
                  || (omnibusPriceMap[product.sku] && isSaleDifference)"
              >
                <span v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax | price(storeView) }}</span>
                <span v-else-if="(omnibusPriceMap[product.sku] && isSaleDifference)">{{ (omnibusPriceMap[product.sku] || product.original_price_incl_tax) * product.totals.qty | price(storeView) }}</span>
              </span>
              <span class="h4 serif price-regular cl-black" v-if="!(product.totals.discount_amount || bundleMinPrice || isSpecialPrice)">
                {{ product.totals.row_total_incl_tax | price(storeView) }}
              </span>
            </div>
            <div class="prices" v-else>
              <span class="h4 serif price-regular cl-black">
                {{ (product.regular_price || product.price_incl_tax) * product.qty | price(storeView) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 pb15 flex flex-wrap bottom-xs" v-if="editMode">
        <div class="ml0 flex flex-wrap filters" v-if="productsAreReconfigurable">
          <div class="h5 pt10 w-100" v-for="(option, index) in product.configurable_options" :key="index">
            <div class="h5 cl-black mr10">
              {{ option.label }}:
            </div>
            <div class="flex flex-wrap pt5" v-if="option.label == 'Color' && editMode">
              <color-selector
                v-for="filter in getAvailableFilters[option.attribute_code]"
                v-if="isOptionAvailable(filter)"
                :key="filter.id"
                :variant="filter"
                :selected-filters="getSelectedOptions"
                @change="changeEditModeFilter"
              />
            </div>
            <div class="flex flex-wrap pt5" v-else-if="option.label == 'Size' && editMode">
              <size-selector
                class="mr10 mb10"
                v-for="filter in getAvailableFilters[option.attribute_code]"
                v-if="isOptionAvailable(filter)"
                :key="filter.id"
                :variant="filter"
                :selected-filters="getSelectedOptions"
                @change="changeEditModeFilter"
              />
            </div>
          </div>
        </div>
        <button-full
          class="update-button mb10 mr10"
          @click.native="updateProductVariant"
          :disabled="isUpdateCartDisabled"
        >
          {{ $t('Update item') }}
        </button-full>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'

import ProductQuantity from 'theme/components/core/ProductQuantity.vue'
import ProductImage from 'theme/components/core/ProductImage'
import ColorSelector from 'theme/components/core/ColorSelector.vue'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
import RemoveButton from './RemoveButton'
import { onlineHelper } from '@vue-storefront/core/helpers'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import { getThumbnailForProduct, getProductConfiguration } from '@vue-storefront/core/modules/cart/helpers'
import ButtonFull from 'theme/components/theme/ButtonFull'
import EditMode from './EditMode'
import { isSpecialPriceActive } from '@vue-storefront/core/modules/catalog/helpers/taxCalc'
import { getBundleOptionsMinPrices } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'

export default {
  data () {
    return {
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    RemoveButton,
    ProductImage,
    ColorSelector,
    SizeSelector,
    ButtonFull,
    ProductQuantity
  },
  mixins: [Product, ProductOption, EditMode],
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      omnibusPriceMap: 'cart/getOmnibusPriceMap'
    }),
    isSaleDifference () {
      if (!this.salePrice || !this.omnibusPriceMap[this.product.sku]) return false
      if ((this.omnibusPriceMap[this.product.sku] - this.salePrice) <= 0) return false
      return true
    },
    salePrice () {
      let productPrice = 0
      if (this.bundleMinPrice) {
        productPrice = this.product.totals.base_price_incl_tax
      } else {
        productPrice = this.product.price_incl_tax
      }
      return Number(productPrice.toFixed(2))
    },
    hasProductInfo () {
      return this.product.info && Object.keys(this.product.info).length > 0
    },
    hasProductErrors () {
      const errors = this.product.errors || {}
      const errorsValuesExists = Object.keys(errors).filter(errorKey => errors[errorKey]).length > 0
      return errorsValuesExists
    },
    isTotalsActive () {
      return this.isOnline && !this.editMode && this.product.totals && this.product.totals.options
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    productsAreReconfigurable () {
      return config.cart.productsAreReconfigurable && this.product.type_id === 'configurable' && this.isOnline
    },
    displayItemDiscounts () {
      return config.cart.displayItemDiscounts
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    thumbnail () {
      return getThumbnailForProduct(this.product)
    },
    configuration () {
      return getProductConfiguration(this.product)
    },
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    editMode () {
      return this.getEditingProductId === this.product.id
    },
    productQty () {
      return this.editMode ? this.getEditingQty : this.product.qty
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.product.type_id)
    },
    isUpdateCartDisabled () {
      return this.quantityError ||
        this.isStockInfoLoading ||
        (this.isOnline && !this.maxQuantity && this.isSimpleOrConfigurable)
    },
    storeView () {
      return currentStoreView()
    },
    bundleMinPrice () { // bundle min price
      return this.isSpecialPrice
        ? getBundleOptionsMinPrices(this.product, this.product.price_type).reduce((memo, current) => memo + current, 0)
        : 0
    },
    isSpecialPrice () {
      return this.product.original_special_price && isSpecialPriceActive(this.product.special_from_date, this.product.special_to_date)
    }
  },
  methods: {
    updateProductVariant () {
      this.updateVariant()
      this.closeEditMode()
    },
    updateProductQty (qty) {
      if (this.editMode) {
        this.editModeSetQty(qty)
        return
      }
      this.updateQuantity(qty)
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    updateQuantity (quantity) {
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity })
    },
    async getQuantity (product) {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const validProduct = product || this.product
        const res = await this.$store.dispatch('stock/check', {
          product: validProduct,
          qty: this.productQty
        })
        return res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    },
    async changeEditModeFilter (filter) {
      const editedProduct = this.getEditedProduct(filter)
      const maxQuantity = await this.getQuantity(editedProduct)
      if (!maxQuantity) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t(
            'The product is out of stock and cannot be added to the cart!'
          ),
          action1: { label: this.$t('OK') }
        })
      } else if (maxQuantity < this.productQty) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Only {maxQuantity} products of this type are available!', { maxQuantity }),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.maxQuantity = maxQuantity
        this.editModeSetFilters(filter)
      }
    }
  },
  watch: {
    isOnline: {
      async handler (isOnline) {
        if (isOnline) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      }
    },
    isMicrocartOpen: {
      async handler (isOpen) {
        if (isOpen) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

  .border-li {
    border-bottom: 1px solid #919191;
    &:last-child {
      border: none;
    }
  }
  .blend {
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
    div {
      border-radius: 9px;
    }
  }

  .image {
    mix-blend-mode: multiply;
    vertical-align: top;
    width: 150px;
    @media (max-width: 767px) {
      width: 100px;
    }
  }

  .details {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
  }

  .name {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .options, .sku {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .qty {
    padding-right: 30px;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .prices {
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }

  .price-special {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .price-original {
    text-decoration: line-through;
  }

  input {
    width: 30px;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }

  // .edit-mode {
  //   border-bottom: 1px solid color(white-smoke);
  // }

  .filters {
    -ms-flex: 1 1 200px;
    flex: 1 1 200px;
  }

  .update-button {
    font-size: 14px;
    min-width: 150px;
    width: 150px;
    padding: 10px;
  }
  .pt-100 {
    padding-bottom: 100% !important;
  }
</style>
