<template>
  <transition name="fade" appear>
    <div class="guide w-100 cl-tertiary" v-if="isOpen">
      <div class="container">
        <div class="row between-xs middle-xs px15">
          <div class="col-xs-12 start-xs relative">
            <img class="swipe" src="/assets/swipe-koszyk-kultowy.gif" alt="assets/swipe-koszyk-kultowy.gif" width="100%">
            <span class="guide-text">{{ $t('Swipe left to change quantity or remove product.') }}</span>
            <i
              class="icon-Close icon p10 pointer close-guide"
              @click="accept"
              @keyup.enter="accept"
              data-testid="closeguideButton"
              tabindex="0"
              role="button"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    setVisited () {
      this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: true })
    }
  },
  created () {
    this.$store.dispatch('claims/check', { claimCode: 'guideAccepted' }).then((guideClaim) => {
      if (!guideClaim) {
        this.isOpen = true
        this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: false })
      } else {
        this.isOpen = !guideClaim.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main-gray: color(main-gray);
$color-main: color(main);
.guide {
  .container {
    display: flex;
    align-items: center;
    height: 89px;
    color: $color-main;
    background-color: $color-main-gray;
  }
  // z-index: 2;
  // top: 46px;
}

.close-guide {
  position: absolute;
  top: -20px;
  right: -10px;
}
.guide-text {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 150px;
  font-size: 12px;
}
</style>
